import React, { Component } from 'react'
import { Carousel } from 'react-responsive-carousel';
import classes from './CarouselImages.module.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "animate.css/animate.min.css";
import { GoProject } from 'react-icons/go'
import { FaArrowCircleDown } from 'react-icons/fa'
import Hello from '../images/Hello.jpg'
import Pencil from '../images/Pencil.jpg'
import {isMobile} from 'react-device-detect';

class CarouselImages extends Component {
    render() {
        return (
            <div className={classes.carousel_container} id="start">
                <Carousel 
                    className={classes.carousel} 
                    dynamicHeight 
                    infiniteLoop={true} 
                    interval={6000} 
                    useKeyboardArrows={true} 
                    transitionTime={1700} 
                    emulateTouch 
                    showArrows={isMobile} 
                    autoPlay 
                    showStatus={false} 
                    showThumbs={false} 
                    swipeable={!isMobile} 
                    swipeScrollTolerance={100} >
                    <div className={classes.image_container} >
                        <img className={classes.image} src={Hello} alt="myImage" />
                        <div className={classes.h1}>
                            <h1 >I'm Ricus</h1>
                            <a href="https://trueburn.co.za/assets/Ricus%20Nortje%20Resume.pdf" rel="opener noreferrer" target="_blank">VIEW CV   <FaArrowCircleDown /></a>
                        </div>
                    </div>
                    <div className={classes.image_container}>
                        <img className={classes.image} src={Pencil} alt="myImage" />
                        <div className={classes.h2}>
                            <h1> I love building</h1>
                            <h1>THINGS!!</h1>
                            <a href="https://github.com/TrueBurn" rel="noopener noreferrer" target="_blank">VIEW PROJECTS   <GoProject /></a>
                        </div>
                    </div>
                </Carousel>
            </div>
        )
    }
}
export default CarouselImages