import React, { Component } from 'react'
import classes from './About.module.css';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

class About extends Component {
    render() {
        return (
            <div className={classes.box} id="about">
                <ScrollAnimation offset={0} animateIn="fadeInLeft" duration={2.4} animateOnce={true} initiallyVisible={true}>
                    <span className={classes.head}>ABOUT ME</span>
                    <h2 className={classes.heading}>Who Am I?</h2>
                    <div className={classes.About}>
                        <p> My name is <b>RICUS NORTJE</b> and I am currently working as an Architect focusing on the mobile app and SRE at <a target="_blank" rel="noopener noreferrer" href="https://www.capitecbank.co.za/"><b>Capitec Bank</b></a>. I am a result driven DevOps enthusiast with years of experience in multiple roles and departments within software development companies. </p>
                        <p className={classes.br}>I strive to obtain functional and technical knowledge of all systems I interact with at a micro and a macro level. Focus is always on high availability, high throughput, maintainability, cost effectivness and measurability</p>
                    </div>
                </ScrollAnimation>
            </div>
        )
    }
}

export default About;