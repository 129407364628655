import React, { Component } from 'react';
import './Sidebar.css';
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../images/trueburn.jpg'

class Sidebar extends Component {
    render() {

        return (
            <div className="sidebar">
                <div style={{ color: 'black', fontWeight: 'bold' }} className="tagtop"> # devops </div>
                <div style={{ color: 'black', fontWeight: 'bold' }} className=""> # full_stack </div>
                <h1><Link smooth to="/#start" className="h1_links">Ricus Nortje</Link></h1>

                <img src={logo} alt="Logo" />
                <p style={{ color: 'black', fontWeight: 'bold' }} className="gmail"><a rel="noopener noreferrer" href="mailto: ricus.nortje@gmail.com" target="_blank" className="fa fa-envelope"></a> ricus.nortje@gmail.com </p>

                <ul className="sidebar-nav">
                    <li className="sidebar-nav-items"><Link smooth to="/#about" className="links">About</Link></li>
                    <li className="sidebar-nav-items"><Link smooth to="/#interest" className="links">Interest</Link></li>
                    <li className="sidebar-nav-items"><Link smooth to="/#education" className="links" >Experience</Link></li>
                </ul>

                <div className="flip-card-back">
                    <ul className="sidebar-nav">
                        <li className="sidebar-nav-icons"> <a href="https://github.com/TrueBurn" rel="noopener noreferrer" target="_blank" className="fa fas fa-github fa-lg"></a></li>
                        <li className="sidebar-nav-icons"> <a href="https://www.linkedin.com/in/ricus-nortje/" rel="noopener noreferrer" target="_blank" className="fa fas fa-linkedin fa-lg"></a></li>
                        <li className="sidebar-nav-icons"> <a href="mailto:ricus.nortje@gmail.com" rel="noopener noreferrer" target="_blank" className="fa fas fa-envelope fa-lg"></a></li>
                    </ul>
                </div>

            </div>
        )
    }
}

export default Sidebar