import React, { Component } from "react";
import classes from "./Education.module.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";
import { MdSchool } from "react-icons/md";
import { MdWork } from "react-icons/md";
import { FaSchool } from "react-icons/fa";

class Education extends Component {
  render() {
    return (
      <div className={classes.box} id="education">
        <ScrollAnimation
          offset={0}
          animateIn="fadeInLeft"
          duration={2}
          animateOnce={true}
          initiallyVisible={true}
        >
          <span className={classes.head}>MY JOURNEY</span>
          <section className={classes.container}>
            <div className={classes.container_content}>
              <div className={classes.row}>
                <div className={classes.row_md_12}>
                  <div className={classes.timeline_centered}>
                    <ScrollAnimation
                      offset={0}
                      animateIn="fadeInLeft"
                      duration={2.4}
                      animateOnce={true}
                      initiallyVisible={true}
                    >
                      <article className={classes.timeline_entry}>
                        <div
                          className={`${classes.timeline_icon} ${classes.timeline_icon_5}`}
                        >
                          <MdWork />
                        </div>
                        <div className={classes.label}>
                          <h2>
                            Architect – Capitec Bank{" "}
                            <span>Oct 2023 to current</span>
                          </h2>
                          <p>
                            Right now I am just learning everything I can and helping out where I can. Will update once I have settled.
                          </p>
                        </div>
                      </article>
                    </ScrollAnimation>
                    <ScrollAnimation
                      offset={0}
                      animateIn="fadeInLeft"
                      duration={2.4}
                      animateOnce={true}
                      initiallyVisible={true}
                    >
                      <article className={classes.timeline_entry}>
                        <div
                          className={`${classes.timeline_icon} ${classes.timeline_icon_5}`}
                        >
                          <MdWork />
                        </div>
                        <div className={classes.label}>
                          <h2>
                            Software Engineer – Redstor{" "}
                            <span>Oct 2022 to Sep 2023</span>
                          </h2>
                          <p>
                            As part of the Platform & Innovation tribe I was
                            part of the team that was responsible for ensuring
                            that the core back-end systems consistently improved
                            and kept up to date. As Redstor is considered a
                            company’s last line of defence for disaster recovery
                            we always needed keep reliability and consistency in
                            mind, and because we deal with client data security
                            was always the number one concern.{" "}
                          </p>
                          <p>
                            The first project I worked on was to provision and
                            port logic from legacy public monoliths into private
                            micro services exposed via an API Gateway. AS I had
                            done this before at other companies, I was able to
                            assist not only in a development capacity but also
                            from a DevOps and operations perspective. I was thus
                            also asked to join the architecture and SRE
                            chapters.
                          </p>
                        </div>
                      </article>
                    </ScrollAnimation>
                    <ScrollAnimation
                      offset={0}
                      animateIn="fadeInLeft"
                      duration={2.4}
                      animateOnce={true}
                      initiallyVisible={true}
                    >
                      <article className={classes.timeline_entry}>
                        <div
                          className={`${classes.timeline_icon} ${classes.timeline_icon_5}`}
                        >
                          <MdWork />
                        </div>
                        <div className={classes.label}>
                          <h2>
                            Full stack engineer – UNi4 Online{" "}
                            <span>Feb 2020 to Oct 2022</span>
                          </h2>
                          <p>
                            When I joined the company, it only had a few PHP
                            powered CMS and LMS sites running on VMs. I then
                            worked closely with the architect to design a system
                            that would meet all the company’s strategic goals. I
                            then started to develop and maintain the systems,
                            making additions and adjustments where required.{" "}
                          </p>
                          <p>
                            I was tasked with designing, developing, and
                            deploying all the infrastructure for the systems
                            with production being cloud first, and development
                            and test on a local DC for cost savings. Every
                            system was created to be performant, highly
                            available, auto correcting with auto scaling based,
                            while keeping cost in mind.
                          </p>
                          <p>
                            The back-end systems were written in C# (first Core
                            3.1 then updated to 6), with a lot of logic
                            extracted into SDKs stored in a private Nuget to
                            standardise and speed up development. They also
                            include centralised logging and distributed tracing.
                            There is a mix between message based (Kafka and
                            SNS\SQS combo) as well as REST. Systems were also
                            created to be multi-tenant with the ability to white
                            label and subset of features for the company to be
                            able to offer PaaS.{" "}
                          </p>
                          <p>
                            I would also mentor and assist the web development
                            team with their projects, as well as assist the
                            inhouse configuration and infrastructure staff.{" "}
                          </p>
                          <p>
                            I also created multiple web apps to provide a IU for
                            finance, academic and student support teams to be
                            able to perform CRUD and reporting operations in
                            bespoke as well as 3rd party integrations (CMS, LMS,
                            SIS, CRM and ERP).
                          </p>
                        </div>
                      </article>
                    </ScrollAnimation>
                    <ScrollAnimation
                      offset={0}
                      animateIn="fadeInLeft"
                      duration={2.4}
                      animateOnce={true}
                      initiallyVisible={true}
                    >
                      <article className={classes.timeline_entry}>
                        <div
                          className={`${classes.timeline_icon} ${classes.timeline_icon_5}`}
                        >
                          <MdWork />
                        </div>
                        <div className={classes.label}>
                          <h2>
                            DevOps SRE – Zapper{" "}
                            <span>Oct 2014 to Jan 2020 (5 yrs 4 mos)</span>
                          </h2>
                          <p>
                            For the first few years my primary role was to
                            provide 2nd and 3rd line support and indecent
                            management for our 2 products. This entailed
                            functional and technical support for all levels from
                            customer facing mobile app, to API, to POS
                            integration systems, to the data stores and other
                            underlying infrastructure. Initially most of the
                            activities done by the support team were manual
                            (running SQL on SSMS or doping REST API calls via
                            Fiddler and Postman), I then started learning ASP
                            MVC as that is the framework our API followed; I
                            then created a personal MVC site to expose data in
                            our data stores and provide a UI for our API calls.
                            This site then grew to becoming an integral part of
                            the business. When new features were first released,
                            I operationalised them on the DevOps site while
                            working with our product team to define business
                            rules until such a point that I can hand over to our
                            back-office system.
                          </p>
                          <p>
                            Thereafter while still providing 2nd and 3rd line
                            support a lot of my focus shifted towards DevOps and
                            SRE related tasks and duties.
                          </p>
                          <p>
                            In addition, I set up multiple bespoke monitoring
                            systems to cover gaps where our corporate monitoring
                            solutions (NewRelic and Site24x7) could not give us
                            the same features; this gave us near real-time
                            indication with multiple streams of notifications of
                            issues within our systems. These monitors also
                            allowed me to create a multitude of dashboards
                            ranging from general overview dashboards tracking
                            transactions flowing into the system in real time to
                            financial dashboards for the CEO to give visual
                            indication of money going in and out of the system.
                          </p>
                          <p>
                            I worked with multiple tech stacks ranging from
                            RabbitMQ/MQTT, to SignalR, to ElasticSearch to Redis
                            and Prometheus; in addition to our core technologies
                            like SQL Server and C#.Net.
                          </p>
                          <p>
                            I also worked closely with the other arms of the
                            operations team (Infostructure; Change and Release
                            Management; Project Management) to ensure that all
                            operational activities would not interfere with any
                            part of our products or services. I also worked with
                            tech leads and architects when designing new
                            functionality to provide insight as to how all our
                            stake holders utilise our products (customers,
                            merchants as well as our marketing teams).
                          </p>
                        </div>
                      </article>
                    </ScrollAnimation>
                    <ScrollAnimation
                      offset={0}
                      animateIn="fadeInLeft"
                      duration={2.4}
                      animateOnce={true}
                      initiallyVisible={true}
                    >
                      <article className={classes.timeline_entry}>
                        <div
                          className={`${classes.timeline_icon} ${classes.timeline_icon_5}`}
                        >
                          <MdWork />
                        </div>
                        <div className={classes.label}>
                          <h2>
                            Senior 2nd line Support – Triple Point Technology{" "}
                            <span>Aug 2010 to Sep 2014 (4 yrs 2 mos)</span>
                          </h2>
                          <p>
                            I provided 1st and 2nd tier support to over 60
                            international companies. I was also part of the ESA
                            team which provided priority support for key
                            customers, which at times required international
                            travel to the clients' office for onsite assistance
                            as well as being part of cross department "SWAT"
                            teams to priority projects. I spent 3 months at the
                            corporate head office in CT, USA to train staff
                            there on our product suits and set up a satellite
                            support office to assist north and south American
                            customers to extend hour support to a full 24/7/365
                            model.
                          </p>
                          <p>
                            Daily duties involved incident management,
                            troubleshooting functionally as well as in source
                            code (TSQL, Delphi, C#.Net), data analysis and minor
                            development of bug fixes, reports on our in-house
                            reporting engine and changes to our UI. I would also
                            assist in QA cycles before major releases roughly
                            every 3 months.
                          </p>
                          <p>
                            As a senior analyst I would need to stand in for the
                            department manager when they were travelling
                            internationally and when on maternity leave. I
                            taught myself the C# by learning the inhouse
                            frameworks by developing internal tools for the
                            support team to use that streamlined our processes.
                          </p>
                        </div>
                      </article>
                    </ScrollAnimation>
                    <ScrollAnimation
                      offset={0}
                      animateIn="fadeInLeft"
                      duration={2.4}
                      animateOnce={true}
                      initiallyVisible={true}
                    >
                      <article className={classes.timeline_entry}>
                        <div
                          className={`${classes.timeline_icon} ${classes.timeline_icon_5}`}
                        >
                          <MdWork />
                        </div>
                        <div className={classes.label}>
                          <h2>
                            Manager: IT & Billing – Vodashop Tygervalley group{" "}
                            <span>Mar 2009 to May 2010 (1 yr 3 mos)</span>
                          </h2>
                          <p>
                            After the successful implementation of the biometric
                            time and attendance system the financial director
                            offered me the chance to take over IT support for
                            the group and oversee the in-house billing system.
                            This meant providing full hardware and software
                            support for all stores and the head offices.
                          </p>
                          <p>
                            Managerial duties entailed supervision and training
                            of IT assistant, billing system administrator and
                            billing system assistant.
                          </p>
                          <p>
                            I then started my interesting in full stack
                            development when I created an intranet. The intranet
                            housed reports and some custom systems as requested
                            by the HR department for shift control across the
                            stores and automation of validation checks for the
                            repair centre. The system was written in Adobe
                            ColdFusion.
                          </p>
                        </div>
                      </article>
                    </ScrollAnimation>
                    <ScrollAnimation
                      offset={0}
                      animateIn="fadeInLeft"
                      duration={2.4}
                      animateOnce={true}
                      initiallyVisible={true}
                    >
                      <article className={classes.timeline_entry}>
                        <div
                          className={`${classes.timeline_icon} ${classes.timeline_icon_5}`}
                        >
                          <MdWork />
                        </div>
                        <div className={classes.label}>
                          <h2>
                            Finance assistant – Vodashop Tygervalley group{" "}
                            <span>Feb 2008 to Mar 2009 (1 yr 2 mos)</span>
                          </h2>
                          <p>
                            I oversaw all aspects involving mobile phone
                            accessories for just over 24 Vodacom stores (5 in
                            the garden route, 1 in Sandton with the rest in the
                            greater Cape Town area). Most of my time was
                            spending on profile maintenance where I needed to
                            ensure that each store had the correct collection
                            and number of accessories to optimise sales, taking
                            into accounts which devices were trending in that
                            store's area. I was also in charge of order
                            fulfilment both from our suppliers as well as to our
                            stores. It was in this role where I started to learn
                            how to design\develop reports and thought myself SQL
                            (on Firebird DB engine) to start doing data mining
                            to be able to get the required data to make informed
                            decisions.
                          </p>
                          <p>
                            I was also the project manager for a biometric time
                            and attendance system implementation. This entailed
                            initial setup of system parameters, fixing of
                            teething problems, design and development of custom
                            reports required by HR department, training of end
                            users, post implementation technical support and
                            system administration.
                          </p>
                        </div>
                      </article>
                    </ScrollAnimation>
                    <ScrollAnimation
                      offset={0}
                      animateIn="fadeInLeft"
                      duration={2.4}
                      animateOnce={true}
                      initiallyVisible={true}
                    >
                      <article className={classes.timeline_entry}>
                        <div
                          className={`${classes.timeline_icon} ${classes.timeline_icon_5}`}
                        >
                          <MdWork />
                        </div>
                        <div className={classes.label}>
                          <h2>
                            Financial officer: LCD Consultants & Instant Mobile
                            Solutions{" "}
                            <span>Aug 2006 to Oct 2007 (1 yr 3 mos)</span>
                          </h2>
                          <p>
                            For the first 6 months I worked as a laptop
                            technician mainly working on refurbishing
                            ex-corporate laptops for resale. Then when the
                            previous financial person left the company, the
                            owner offered me the position of financial officer.
                            I then handled the accounting up to the trial
                            balance, including inventory control and Pastel
                            administration.
                          </p>
                        </div>
                      </article>
                    </ScrollAnimation>
                    <ScrollAnimation
                      offset={0}
                      animateIn="fadeInLeft"
                      duration={2.4}
                      animateOnce={true}
                      initiallyVisible={true}
                    >
                      <article className={classes.timeline_entry}>
                        <div
                          className={`${classes.timeline_icon} ${classes.timeline_icon_2}`}
                        >
                          <FaSchool />
                        </div>
                        <div className={classes.label}>
                          <h2>
                            Matric <span>2004</span>
                          </h2>
                          <p>
                            Matriculated from Curro Durbaville Private School
                          </p>
                        </div>
                      </article>
                    </ScrollAnimation>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </ScrollAnimation>
      </div>
    );
  }
}

export default Education;
